






import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import decamelize from 'decamelize';

import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside, BMediaBody,
  BRow
} from "bootstrap-vue";

@Component({
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody
  }
})
export default class MaintenanceDashboard extends Vue {
  decamelize(value, options) { return decamelize(value, options) }

  async mounted() {
    //
  }



  @Watch("$route.fullPath")
  async watchPath() {
//
  }
}
